import { useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { Button, ButtonType, RichText } from '@lamesarv-sdk/components';
import { getFacetCode } from '@lamesarv-sdk/tools';
import { IComponentBase, PropsWithClasses } from '@lamesarv-sdk/types';

interface IContentBlock extends PropsWithClasses {
  title: string;
  description: string;
  isOpen?: boolean;
  classButton?: string;
}

const customRemarkComponents = {
  a: ({ children, href }) => (
    <a className={twMerge('text-blue-600 underline underline-offset-4 hover:decoration-dashed')} href={href || '#'}>
      {children}
    </a>
  ),
};

const ContentBlock = (props: IContentBlock) => {
  const [isOpen, setIsOpen] = useState<boolean>(props.isOpen || false);

  return (
    <div className="flex flex-col gap-6 pb-6 border-b border-gray-200 last:border-none">
      <div className="w-full relative">
        <h3 className={twMerge('text-xl font-medium pr-12', props.classTitle)}>{props.title}</h3>
        <div className="absolute top-1/2 -translate-y-1/2 right-0">
          <Button
            type={ButtonType.flat}
            icon={
              isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} className="w-4 h-4" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} className="w-4 h-4" />
              )
            }
            onClick={() => setIsOpen(!isOpen)}
            className={twMerge('leading-none hover:bg-transparent', props.classButton)}
          />
        </div>
      </div>
      {isOpen && (
        <RichText
          content={props.description}
          customRemarkComponents={customRemarkComponents}
          className={props.classContent}
        />
      )}
    </div>
  );
};

export interface IComponentAccordionContent extends IComponentBase, PropsWithClasses {
  blocks?: IContentBlock[];
  classButton?: string;
}

export const AccordionContent = (props: IComponentAccordionContent) => {
  return (
    <div className="p-4 md:p-8">
      <div className={twMerge('flex flex-col gap-6 max-w-7xl mx-auto', props.className)}>
        {props.blocks?.map((block) => <ContentBlock key={getFacetCode(block.title)} {...props} {...block} />)}
      </div>
    </div>
  );
};
